.code-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.code {
  width: 20%;
  min-width: 120px;
  margin-bottom: 1rem;
}

.code img {
  width: auto;
  max-width: 80px;
  height: 73px;
}

.code p {
  font-family: "Raleway", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
}

.about-card {
  position: relative;
  margin: 4rem auto;
  z-index: 1;
  background-color: rgba(216, 232, 231, 0.7);
  color: rgb(1, 12, 18);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style:initial;
  width: 90%;
  max-width: 800px;
  padding: 2rem;
  border-radius: 5px;
  text-align:start;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.typed {
  font-family:monospace;
  font-size:12px;
  color: black;
}
@media (max-width: 768px) {
  .code {
    width: 30%;
  }

  .about-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .code {
    width: 45%;
  }

  .code img {
    width: 50%;
  }

  .code p {
    font-size: 0.8rem;
  }

  .about-card {
    padding: 1rem;
  }
}