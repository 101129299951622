.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem auto;
  max-width: 1200px;
}

.project-link {
  display: block;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
}

.project-link:hover {
  transform: scale(1.05);
}

.project-image {
  width: 300px; /* Set a fixed width */
  height: 300px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the entire area */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.projects-heading {
  color: rgb(241, 234, 234);
  text-shadow: 2px 2px 2px rgb(7, 0, 0);
}

.project-name {
  margin-top: 0.5rem;
  text-align: center;
  font-weight: bold;
}

.contact-me {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.contact-me img {
  width: 40px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 2rem;
  color: white;
}

.contact-me h2 {
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 2rem;
  font-family: monospace;
}

@media (max-width: 768px) {
  .portfolio-container {
    gap: 1rem;
  }

  .project-image {
    width: 250px; /* Adjust width for smaller screens */
    height: 250px; /* Adjust height for smaller screens */
  }

  .contact-me {
    flex-direction: column;
    align-items: center;
  }

  .contact-me img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
