.contact-container {
    background: linear-gradient(45deg, #e3e8ec, #b7b4b7);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0.75rem auto;
  }
  
  .logo-link {
    display: inline-block;
  }
  
  .logo-image {
    margin-top: 3px;
    margin-bottom: 5px;
    width: 40px;  
    height: 40px; 
    object-fit: contain;
    transition: opacity 0.3s ease;
  }
  
  .logo-image:hover {
    opacity: 0.8;
  }
  
  /* Media query for larger screens */
  @media (min-width: 768px) {
    .logo-image {
      width: 50px;  /* Slightly larger on bigger screens */
      height: 50px;
    }
  }